<!-- Version: 1.0 -->
<template>
  <div class="eh-panel-card" :style="error ? 'border-color:red' : ''">
    <div
      @click="cardOpenClose()"
      v-if="title || description"
      class="eh-panel-card-title"
      :class="closePanelCard ? 'eh-panel-title-long-box' : ''"
    >
      <slot name="title">
        <div v-if="title">
          <span>{{ title }}</span>
          <span v-if="required" class="text-danger">*</span>
        </div>
      </slot>
      <div v-if="description">
        <span class="eh-panel-card-description"><div v-html="description"></div></span>
      </div>
    </div>
    <div
      v-if="!closePanelCard || closedPanelRender"
      v-show="!closePanelCard"
      class="eh-panel-card-content-box"
    >
      <slot name="content"></slot>
    </div>
    <!--    TODO TIBI: az alabbi harom row-t a fenti div-be kell helyezni (nem kell a closePanelCard)-->
    <b-row
      v-if="isShowAddButton && isShowDeleteButton && !closePanelCard"
      align-h="between"
    >
      <div
        v-if="isShowAddButton"
        class="eh-panel-card-left-button"
        @click="$emit('addNewElement')"
      >
        <b-icon class="h3" icon="plus-circle"></b-icon>
      </div>
      <div
        v-if="isShowDeleteButton"
        class="eh-panel-card-right-button"
        @click="$emit('deleteElement')"
      >
        <b-icon class="h3" icon="trash"></b-icon>
      </div>
    </b-row>
    <b-row
      v-else-if="!isShowAddButton && isShowDeleteButton && !closePanelCard"
      align-h="end"
    >
      <div
        v-if="isShowDeleteButton"
        class="eh-panel-card-right-button"
        @click="$emit('deleteElement')"
      >
        <b-icon class="h2" icon="trash"></b-icon>
      </div>
    </b-row>
    <b-row
      v-else-if="isShowAddButton && !isShowDeleteButton && !closePanelCard"
      align-h="start"
    >
      <div
        v-if="isShowAddButton"
        class="eh-panel-card-left-button"
        @click="$emit('addNewElement')"
      >
        <b-icon class="h2" icon="plus-circle"></b-icon>
      </div>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "fp-panel-card",
  data() {
    return {
      closePanelCard: this.closedCard,
    };
  },
  props: {
    title: String,
    description: String,
    isShowAddButton: Boolean,
    isShowDeleteButton: Boolean,
    required: Boolean,
    closedCard: Boolean,
    error: { type: Boolean, default: false },
    closedPanelRender: { type: Boolean, default: true },
  },
  whatch: {
    closedCard(input) {
      console.log(input);
      this.closePanelCard = input;
    },
  },
  methods: {
    cardOpenClose() {
      this.closePanelCard = !this.closePanelCard;
      this.$emit("cardOpen", !this.closePanelCard);
    },
  },
};
</script>
